import Frame2 from "../../../images/landingPageImages/Frames/Frame2.webp";
import Frame3 from "../../../images/landingPageImages/Frames/Frame3.webp";
import Frame4 from "../../../images/landingPageImages/Frames/Frame4.webp";
import Frame5 from "../../../images/landingPageImages/Frames/Frame5.webp";
import Frame6 from "../../../images/landingPageImages/Frames/Frame6.webp";
import Frame7 from "../../../images/landingPageImages/Frames/Frame7.webp";

export const placementsData = [
  Frame2,
  Frame3,
  Frame4,
  Frame5,
  Frame6,
  Frame7,
];
