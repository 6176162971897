import React from "react";
import "./styles.scss";



const index = ({handleBtnClick}) => {
  return (
    <div className="lp-tools">
      <div className="lp-tools-hero">
        <div className="l-tools-head">
          Digital Marketing Tools and AI Tools you will master
        </div>
        <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm28kqanp.png" className="lp-tools-underline" alt="" />
        <div className="l-tools-desc">
          Here’s a list of various digital marketing tools that you’ll be
          introduced to in our course.<br className="pc-none" /> You’ll be taught how to use these as
          knowing about these <br className="mobile-none" />
          tools is going to make your life easier as you grow further in your
          career.
        </div>
      </div>
      <div className="tools-used-container mobile-hide">
        <div className="mlp-tool">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21fme2r.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h9i6h.webp"
            style={{ scale: "0.8" }}
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21ftd63.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gc85r.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gdpp0.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
        </div>
        <div className="mlp-tool">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gt4rn.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gveid.webp"
            style={{ scale: "0.75" }}
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21gxz9w.webp"
            style={{ scale: "1.5" }}
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h04ev.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h23jg.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
        </div>{" "}
        <div className="mlp-tool">
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21h9i6h.webp"
            alt=""
            style={{ scale: "0.8" }}
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hcu1b.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hez48.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hgvki.webp"
            style={{ scale: "1.25" }}
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hxshn.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool-sm"
          />
          <img
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m21hzrv3.webp"
            alt=""
            loading="lazy"
            className="mlp-part-time-tool"
          />
        </div>
      </div>
      <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yue7ll.webp" className="lp-tools-mob" loading="lazy" />
      <div className="btn-container-full">
        <button className="lp-dmc-download-mob" onClick={() => handleBtnClick('Download Brochure')}>Get Detailed Brochure</button>
      </div>
    </div>
  );
};

export default index;
