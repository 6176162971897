import BgImg1 from "../../../images/landingPageImages/Training/trainers1.webp";
import BgImg2 from "../../../images/landingPageImages/Training/trainers2.webp";
import BgImg3 from "../../../images/landingPageImages/Training/trainers3.webp";
import BgImg4 from "../../../images/landingPageImages/Training/trainers4.webp";
import BgImg5 from "../../../images/landingPageImages/Training/trainers5.webp";
import BgImg6 from "../../../images/landingPageImages/Training/trainers6.webp";
import BgImg7 from "../../../images/landingPageImages/Training/trainers7.webp";
import BgImg8 from "../../../images/landingPageImages/Training/trainers8.webp";
import BgImg9 from "../../../images/landingPageImages/Training/trainers9.webp";
import BgImg10 from "../../../images/landingPageImages/Training/trainers10.webp";
import BgImg11 from "../../../images/landingPageImages/Training/trainers11.webp";
import BgImg12 from "../../../images/landingPageImages/Training/trainers12.webp";
import BgImg13 from "../../../images/landingPageImages/Training/trainers13.webp";
import BgImg14 from "../../../images/landingPageImages/Training/trainers14.webp";
import newsCom1 from "../../../images/landingPageImages/Companies/compImg1.webp";
import newsCom2 from "../../../images/landingPageImages/Companies/compImg2.webp";
import newsCom3 from "../../../images/landingPageImages/Companies/compImg3.webp";
import newsCom4 from "../../../images/landingPageImages/Companies/compImg4.webp";
import newsCom5 from "../../../images/landingPageImages/Companies/compImg5.webp";
import newsCom6 from "../../../images/landingPageImages/Companies/compImg6.webp";
import newsCom12 from "../../../images/landingPageImages/Companies/compImg12.webp";
import newsCom14 from "../../../images/landingPageImages/Companies/compImg14.webp";

export const newsData = [
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Prachi's+Kraftshala+Story+++Marketing+Launchpad+Program.mp4",
    color: '#02BFF5',
    image: BgImg1,
    compImage: newsCom1,
    name: 'Prachi Kothiyal'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/My+Journey+From+Civil+Engineering+to+Digital+Marketing+++Kraftshala+Reviews+++Marketing+Launchpad.mp4",
    color: '#F28746',
    image: BgImg2,
    compImage: newsCom2,
    name: 'Somes Biswal',
    width: '140px', Mobwidth: '90px',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Paulson's+Journey+From+Engineering+to+Marketing+-+Kraftshala+Review.mp4",
    color: '#F1AE13',
    image: BgImg3,
    compImage: newsCom3,
    name: 'Paulson Joseph'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Perks+of+Being+a+Kraftshala+Alumni+at+Mediamint+++Kraftshala+Reviews+++Marketing+Launchpad+Reviews.mp4",
    color: '#64D8A5',
    image: BgImg4,
    compImage: newsCom4,
    name: 'Shivam',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Charu's+Kraftshala+Story+++Marketing+Launchpad+Program.mp4",
    color: '#427EB2',
    image: BgImg5,
    compImage: newsCom5,
    name: 'Charu Kulsreshtha',
    width: '140px', Mobwidth: '90px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+to+Switch+From+Engineering+to+Marketing+++Kraftshala+Reviews+++Marketing+Launchpad+Reviews.mp4",
    color: '#02BFF5',
    image: BgImg6,
    compImage: newsCom6,
    name: 'Prathamesh',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+Does+Kraftshala's+Training+Process+Look+Kraftshala+Alumni+Reviews+++Marketing+Launchpad+Review.mp4",
    color: '#F28746',
    image: BgImg7,
    compImage: newsCom2,
    name: 'Suyash',
    width: '140px', Mobwidth: '90px',
    mtop: 2
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/How+Does+Digital+Marketing+Career+After+Kraftshala+Look+Like+Kraftshala+Placements.mp4",
    color: '#F1AE13',
    image: BgImg8,
    compImage: newsCom6,
    name: 'Pratyush Sarkar',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-From+Engineer+to+Marketer+-+My+Career+Transition+Journey+With+Kraftshala+_+Kraftshala+Experience.mp4",
    color: '#64D8A5',
    image: BgImg9,
    compImage: newsCom1,
    name: 'Chethan Malpe'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-Why+Does+Sahil+Think+Kraftshala+is+Incomparable_+Kraftshala+Reviews+_+Marketing+Launchpad+Reviews.mp4",
    color: '#427EB2',
    image: BgImg10,
    compImage: newsCom6,
    name: 'Sahil Mirza',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/From+Football+Coach+to+a+Digital+Marketer+at+Publicis+Global+Delivery+++Kraftshala+Reviews.mp4",
    color: '#02BFF5',
    image: BgImg11,
    compImage: newsCom6,
    name: 'Bharat',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Career+in+Digital+Marketing+WITHOUT+English+Kraftshala+Reviews++Kraftshala+Experience.mp4",
    color: '#F28746',
    image: BgImg12,
    compImage: newsCom12,
    name: 'Sonia Prasad',
    width: '90px', Mobwidth: '56px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/Is+Kraftshala+the+Best+Choice+to+Pursue+Core+Marketing+Kraftshala+Marketing+Launchpad+Reviews.mp4",
    color: '#F1AE13',
    image: BgImg13,
    compImage: newsCom6,
    name: 'Abhishek Sharda',
    width: '120px', Mobwidth: '80px'
  },
  {
    link: "https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/no-auth-uplaod/Report/SnapSave.io-How+Does+Kraftshala+Help+in+Switching+From+Engineering+to+Marketing_+Kraftshala+Reviews.mp4",
    color: '#23B180',
    image: BgImg14,
    compImage: newsCom14,
    name: 'Harish Tureha',
    width: '146px'
  },

  
];
